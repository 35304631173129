import React from "react";

const ChatviewModal = ({ title, children, modalOpen = false, setModalOpen }) => {
  return (
    <div>
      <input
        value={modalOpen}
        type='checkbox'
        checked={modalOpen}
        onChange={() => setModalOpen(!modalOpen)}
        className='modal-toggle'
      />
      <div class="modal pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center" style={{backgroundColor: 'white'}}>
        <div className='relative py-0' style={{width:"100vw", height:"100vh"}}>
          <h3 className='text-sm font-bold px-1'>{title}</h3>
          <div className='py-0'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ChatviewModal;
