import React, { useEffect, useState, useContext } from 'react';
import { ChatContext } from '../context/chatContext';
import { submitForm } from '../utils/submitForm';
import content from "../textMessages/content.json";

const SupportSetting = ( { setModalOpen } ) => {
  const [input, setInput] = useState('');
  const [email, setEmail] = useState('');
  const [messages, , , , , ] = useContext(ChatContext);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const [lastFiveHistory, setLastFiveHistory] = useState([]);
  
  useEffect(() => {
    const filteredHistory = messages;
    const lastFive = filteredHistory.length > 5 ? filteredHistory.slice(filteredHistory.length-5) : filteredHistory;
    setLastFiveHistory(lastFive);
  }, [messages]);


  const submit = async e => {
    e.preventDefault();
    setLoading(true);
    setErrorMsg('');
    const fiveMsg = lastFiveHistory;
    const quest = input;
    const usrEmail = email;
    if (input.length === 0 || usrEmail.length === 0) {
        setErrorMsg('Please complete the form!');
        setLoading(false);
        return;
    }
    try {
        const success = await submitForm(fiveMsg, usrEmail, quest);
        if (success) {
            window.alert(
                content.supportMsg.successfulSentWindowAlertMsg
            );
            setModalOpen(false);
        } else {
            window.alert(content.supportMsg.failedSentWindowAlertMsg);
        }
    } catch (err) {
        console.error('Error:', err)
        setErrorMsg(
            'Error: An error occured while sending your questions'
        );
    };
    setLoading(false);
  };

  return (
    <form
      onSubmit={submit}
      className='flex flex-col items-center justify-center gap-2'>
      <p className='text-xs font-semibold'>Your Email:</p>
      <input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className='w-full max-w-xs input input-bordered'
      />
      <p className='text-xs font-semibold'>Please tell about your questions:</p>
      <input
        value={input}
        onChange={(e) => setInput(e.target.value)}
        className='w-full max-w-xs input input-bordered'
      />
      {/* <div className='w-full max-w-xs'>
        <Dropdown prop={selected} setProp={setSelected} dropdownList={selections} error={selectError} defaultValue={"Topic"}/>
      </div> */}
      <button disabled={loading} className='w-full max-w-xs btn btn-outline'>
        {loading ? (
          <span className='w-56 progress progress-info' />
        ) : (
          'Submit'
        )}
      </button>
      <p>{errorMsg}</p>
    </form>
  );
};

export default SupportSetting;
