import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Dropdown from './Dropdown';
import { verifyLogin } from '../utils/checkPassword';
import { useState, useEffect } from 'react';
import content from '../textMessages/content.json';

const Copyright = (props) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props} className='text-xs'>
      {'Copyright © '}
      <Link color="inherit" href="https://orbbec3d.com/">
        Orbbec Inc.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const SignUp = ({setModalOpen}) => {

    const [location, setLocation] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [locationError, setLocationError] = useState(false)
    const [firstNameError, setfirstNameError] = useState(false)
    const [lastNameError, setLastNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [emailErrorMsg, setEmailErrorMsg] = useState("")
    const isValidEmail = (emailaddr) => {
      var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(emailaddr).toLowerCase());
    }
    
    useEffect(() => {
        if (location !== "")
            setLocationError(false);
        if (firstName !== "")
            setfirstNameError(false);
        if (lastName !== "")
            setLastNameError(false);
        if (email !== "")
            setEmailError(false);
    }, [location, firstName, lastName, email])

    useEffect(() => {
        if (email !== "" & !isValidEmail(email)) {
            setEmailError(true);
            setEmailErrorMsg("Please enter a valid email address")
        }
        else {
            setEmailErrorMsg("")
        }
    }, [email])

    const errorCheckingOnSubmit = () => {
        var noErr = true;
        if (location === "") {
            setLocationError(true);
            noErr = false;
        }
        if (firstName === "") {
            setfirstNameError(true);
            noErr = false;
        }
        if (lastName === "") {
            setLastNameError(true);
            noErr = false;
        }
        if (email === "") {
            setEmailError(true);
            noErr = false;
        }
        return noErr;
    }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (errorCheckingOnSubmit()){
        const loginObject = {
            "firstName": firstName,
            "lastName": lastName,
            "email": email,
            "location": location
        };
        try {
            const response = await verifyLogin(loginObject);
            console.log(response);
            const [success, loginToken] = response;
            if (success) {
              window.localStorage.setItem('login-token', loginToken);
              console.log('Login successful');
              setModalOpen(false);
            } else {
              console.log('Login failed');
            //   setErrorMsg('Error: Incorrect Authentication');
            }
          } catch (error) {
            console.error('Error:', error);
            // setErrorMsg('Error: An error occurred while verifying login');
          }
        
        // setLoading(false);
        setModalOpen(false);
    }
   
  };

  const handleFirstNameChange = (event) => {
    event.preventDefault();
    setFirstName(event.target.value);
  }

  const handleLastNameChange = (event) => {
    event.preventDefault();
    setLastName(event.target.value);
  }

  const handleEmailChange = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  }

  const menuList = content.signUpDropdownMenu;

    return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" className='py-0'>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main', width: 25, height: 25}}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h8">
            Please Fill Up the Info
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Grid container spacing={1} >
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onChange={handleFirstNameChange}
                  error={firstNameError}
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  onChange={handleLastNameChange}
                  error={lastNameError}
                  size='small'
                />
              </Grid>
              <Grid item xs={12}>
              <FormControl required style={{ width: '100%' }} error={emailError}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={handleEmailChange}
                  size='small'
                />
                {<FormHelperText>{emailErrorMsg}</FormHelperText>}
                </ FormControl>
              </Grid>
              <Grid item xs={12} style={{zIndex: 1}}>
                <Dropdown prop={location} setProp={setLocation} dropdownList={menuList} error={locationError} defaultValue={'Location'}/>
              </Grid>
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid> */}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
            >
              Sign Up
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 2 }} />
      </Container>
    </ThemeProvider>
  );
}

export default SignUp;