import { useState } from 'react';
import content from '../textMessages/content.json'

/**
 * A custom hook for managing the conversation between the user and the AI.
 *
 * @returns {Object} An object containing the `messages` array and the `addMessage` function.
 */
const useMessageCollection = () => {
  const [currentTitle, setCurrentTitle] = useState("");
  const welcomMsg = content.welcomeMsg.content;
  const initialMsg = {
    id: 1,
    createdAt: Date.now(),
    text: `${welcomMsg}`,
    ai: true,
    title: ""
  }
  const [messages, setMessages] = useState([initialMsg]);
  /**
  * A function for adding a new message to the collection.
  *
  * @param {Object} message - The message to add to the collection.
  */
  const addMessage = (message) => {
    setMessages((prev) => [...prev, message]);
  }

  const clearMessages = () => setMessages([initialMsg])
  const addNewChat = () => {
    setCurrentTitle("");
  }
  
  const changeCurrentTitle = (newTitle) => {
    setCurrentTitle(newTitle);
  }

  return [messages, addMessage, clearMessages, addNewChat, currentTitle, changeCurrentTitle];
}

export default useMessageCollection