import { ChatContextProvider } from './context/chatContext';
import ChatView from './components/ChatView';
import { useEffect, useState } from 'react';
import SignUp from './components/SignUp';
import ChatviewModal from './components/ChatviewModal';

const App = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const loginToken = "8d87b944f462ad17982b7130b6a180bb6c241a926ba2017f3aa583f2f73ee8c8OrbbecDingGuaGua";

  useEffect(() => {
    const token = window.localStorage.getItem('login-token');
    if (!token || token !== loginToken) {
      console.log("no token")
      setModalOpen(true);
    }
  }, []);
  return (
    <ChatContextProvider>
      {modalOpen && <ChatviewModal title='Sign In' modalOpen={modalOpen} setModalOpen={setModalOpen} >
        {/* <Setting modalOpen={modalOpen} setModalOpen={setModalOpen} /> */}
        <SignUp setModalOpen={setModalOpen} />
      </ChatviewModal>}
      {/* {hasToken && supportModalOpen && <Modal title='Talk To Cosutmer Support' modalOpen={supportModalOpen} setModalOpen={setSupportModalOpen}>
        <SupportSetting setModalOpen={setSupportModalOpen} />
      </Modal>} */}
      <ChatviewModal title='OrbbecGPT is here to assit you!' modalOpen={!modalOpen}> 
        <ChatView supportModalOpen={supportModalOpen} setSupportModalOpen={setSupportModalOpen} />
      </ChatviewModal>
      {/* <div className='flex transition duration-500 ease-in-out'>
        <SideBar setSupportModalOpen={setSupportModalOpen} />
        <ChatView />
      </div> */}
    </ChatContextProvider>
  );
};

export default App;