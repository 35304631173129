import React, { useState, useRef, useEffect, useContext, useLayoutEffect } from 'react';
import ChatMessage from './ChatMessage';
import { ChatContext } from '../context/chatContext';
import Thinking from './Thinking';
import { MdSend } from 'react-icons/md';
import Filter from 'bad-words';
import Modal from './Modal';
import Setting from './Setting';
import SupportSetting from './SupportSetting';
import content from "../textMessages/content.json";

/**
 * A chat view component that displays a list of messages and a form for sending new messages.
 */
const ChatView = ({ supportModalOpen, setSupportModalOpen }) => {
  const messagesEndRef = useRef();
  const inputRef = useRef();
  const [formValue, setFormValue] = useState('');
  const [thinking, setThinking] = useState(false);
  const options = content.gptOptions;
  const selected = options[2];
  const [messages, addMessage, , , currentTitle, changeCurrentTitle] = useContext(ChatContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentChats, setCurrentChats] = useState([]);
  const curTitle = useRef("");
  const products = content.productList;

  /**
   * Scrolls the chat area to the bottom.
   */
  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end" })
    }, 0)
  };

  /**
   * Adds a new message to the chat.
   *
   * @param {string} newValue - The text of the new message.
   * @param {boolean} [ai=false] - Whether the message was sent by an AI or the user.
   */
  const updateMessage = (newValue, ai = false, selected) => {
    const id = Date.now() + Math.floor(Math.random() * 1000000);
    const newMsg = {
      id: id,
      createdAt: Date.now(),
      text: newValue,
      ai: ai,
      selected: `${selected}`,
      title: curTitle.current
    };

    addMessage(newMsg);
  };

  /**
   * Sends our prompt to our API and get response to our request from openai.
   *
   * @param {Event} e - The submit event of the form.
   */
  const sendMessage = async (e) => {
    e.preventDefault();

    const key = window.localStorage.getItem('login-token');
    if (!key) {
      setModalOpen(true);
      return;
    }

    const filter = new Filter();
    const cleanPrompt = filter.isProfane(formValue)
      ? filter.clean(formValue)
      : formValue;

    const newMsg = cleanPrompt;
    const aiModel = selected;

    setThinking(true);
    setFormValue('');
    
    
    if (currentTitle === "") {
      curTitle.current = newMsg;
      changeCurrentTitle((prevTitle) => newMsg);
    }
    updateMessage(newMsg, false, aiModel);
    let data;
    try {
      let proDuctInfo = "";
      products.forEach((product, idx) => {
        if (newMsg.toLowerCase().includes(product)) {
          proDuctInfo = content.productInfoSentence;
        }
      })
      const url = content.gptAskURL;
      const response = await fetch(url, {
        method: 'POST',
        
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query: newMsg })
      });

      const res1 = await response.json();
      data = res1.response;
      data += proDuctInfo;
    } catch (err) {
      data = content.userMsgNotSentErr;
    }
    data && updateMessage(data, true, aiModel);
    setThinking(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // 👇 Get input value
      sendMessage(e);
    }
  };

  /**
   * Scrolls the chat area to the bottom when the messages array is updated.
   */

  useLayoutEffect(() => {
    scrollToBottom();
  }, [messages, thinking]);

  /**
   * When the currentTitle and messages changes, reset the previousChats
   */
  useEffect(() => {
    curTitle.current = currentTitle;
    setCurrentChats(
      messages.filter(
        (previousChat) => previousChat.title === currentTitle
      )
    );
  }, [messages, currentTitle]);
  /**
   * Focuses the TextArea input to when the component is first rendered.
   */
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <div className='chatview' style={{height: 'calc(100vh - 2.2rem)', width: '100%'}}>
      {supportModalOpen && <div>
        <Modal title='Talk to us!' modalOpen={supportModalOpen} setModalOpen={setSupportModalOpen}>
          <SupportSetting setModalOpen={setSupportModalOpen} />
        </Modal>
        </div>}
      <main className='chatview__chatarea' >
        {currentChats.map((message, index) => (
            <ChatMessage key={index} message={{ ...message }} func={setSupportModalOpen} />
        ))}

        {thinking && <Thinking />}

        <span ref={messagesEndRef}></span>
      </main>
      <form className='form' onSubmit={sendMessage} style={{height: '2.5rem'}}>
        <div className='flex items-stretch justify-between w-full mt-auto'>
          <textarea
            ref={inputRef}
            className='chatview__textarea-message'
            value={formValue}
            onKeyDown={handleKeyDown}
            onChange={(e) => setFormValue(e.target.value)}
          />
          <button
            type='submit'
            className='chatview__btn-send'
            disabled={!formValue}>
            <MdSend style={{marginLeft: -5}} size={17} />
          </button>
        </div>
      </form>
      <Modal title='Setting' modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <Setting modalOpen={modalOpen} setModalOpen={setModalOpen} />
      </Modal>
    </div>
  );
};

export default ChatView;