import content from "../textMessages/content.json";

export async function submitForm(lastFiveHistory, usrEmail, useQuestion) {
    let emailBody = '';
    console.log(usrEmail);
    lastFiveHistory.forEach((que, idx) => {
        emailBody += `${idx}: ${que.text}\n`
    });
    const data = {
        emailBody: emailBody,
        usrEmail: usrEmail,
        useQuestion: useQuestion
    }
    try { 
        const response = await fetch(content.supportMsg.URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        const result = await response.json()
        if (response.ok) {
            if (result.success) {
                return true;
            } else {
                return false;
            }
        }
    } catch (err) {
        console.error(err);
        return false
    }
    return true;
}