import React, { useContext } from 'react';
import { MdComputer } from 'react-icons/md';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import moment from 'moment';
import Image from './Image';
import person from '../assets/person.png';
import Support from './Support';
import { ChatContext } from '../context/chatContext';
import ReactLinkify from 'react-linkify';

/**
 * A chat message component that displays a message with a timestamp and an icon.
 *
 * @param {Object} props - The properties for the component.
 */

const keywords = ['int', 'float', 'if', 'for', 'while', 'char', 'double', 'bool', 'class', 'public', 'private', 'protected', 'virtual', 'override', 'auto', 'const'];

const formatCppCode = (code) => {
  const charactersPattern = /([{};])/g;
  const preKeywordPattern = new RegExp(`(\\b(?:${keywords.join('|')})\\b)`, 'g');
  const commentPattern = /(\/\/[^\n]*)([ ]+)(.*::.*|\..*)/g;

  let formattedCode = code
    .replace(/#include\s*<[^>]*>/g, match => '\n' + match)
    .replace(charactersPattern, '$1\n')
    .replace(preKeywordPattern, '\n$1')
    .replace(commentPattern, '$1\n$3');

  let lines = formattedCode.split('\n').map(line => line.trim());

  let indentLevel = 0;
  lines = lines.map(line => {
    if (line.endsWith('{')) {
      const indent = ' '.repeat(indentLevel * 4);
      indentLevel++;
      return indent + line;
    } else if (line.startsWith('}')) {
      indentLevel = Math.max(indentLevel - 1, 0);
      const indent = ' '.repeat(indentLevel * 4);
      return indent + line;
    } else {
      const indent = ' '.repeat(indentLevel * 4);
      let result = indent + line;
      if (line.endsWith('}')) indentLevel = Math.max(indentLevel - 1, 0);
      return result;
    }
  });

  formattedCode = lines.join('\n');
  formattedCode = formattedCode.trim();

  return formattedCode;
};

const formatCode = (mashedCode) => {
  const languages = ["cpp", "c", "java"];
  let detectedLanguage = "Unknown language";
  let formattedCode = "Could not format the code";

  for (var i = 0; i < languages.length; i++) {
    if (mashedCode.startsWith(languages[i])) {
      detectedLanguage = languages[i];
      // Remove the language string
      const code = mashedCode.substring(detectedLanguage.length);

      formattedCode = formatCppCode(code);
      break;
    }
  };

  if (detectedLanguage === "Unknown language") {
    formattedCode = formatCppCode(mashedCode);
  }

  return formattedCode;
}

const ChatMessage = (props) => {
  const { id, createdAt, text, ai = false, selected } = props.message;
  const setSupportModalOpen = props.func;
  const [messages] = useContext(ChatContext);
  
  const containsCode = text.includes('```');

  return (
    <div
      key={id}
      className={`${ai && 'flex-row-reverse bg-light-white'} message`}>
      {selected === 'DALL·E' && ai ? (
        <Image url={text} />
      ) : (
        <div className='message__wrapper'>
          {containsCode ? (
            <ReactMarkdown
              className={`message__markdown ${ai ? 'text-left' : 'text-right'}`}
              children={text}
              remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
              components={{
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || 'language-js');
                  return (
                    <SyntaxHighlighter
                      children={formatCode(String(children).replace(/\n$/, ''))}
                      style={oneDark}
                      language={match[1]}
                      PreTag='div'
                      {...props}
                    />
                  ) 
                },
              }}
              style={{ fontSize: '12px', paddingLeft: '10px', paddingRight: '10px' }} // Added this
            />
          ) : (
            <ReactLinkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              <p style={{ fontSize: '12px', paddingLeft: '10px', paddingRight: '10px' }}>{text}</p>
            </ReactLinkify>
          )}
          {messages.length > 1 && ai && <Support setSupportModalOpen={setSupportModalOpen}/>}
          <div
            className={`${ai ? 'text-left' : 'text-right'} message__createdAt`}>
            {moment(createdAt).calendar()}
          </div>
        </div>
      )}

      <div className='message__pic'>
        {ai ? (
          <div className='avatar'>
            <div className='w-8 border rounded-full'>
              <MdComputer className='w-3 h-full mx-auto' />
            </div>
          </div>
        ) : (
          <div className='avatar'>
            <div className='w-5 border rounded-full'>
              <img src={person} alt='profile pic' />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatMessage;
