import React from 'react';
import { MdSupportAgent } from 'react-icons/md';
import Button from '@mui/material/Button';

const Support = ({setSupportModalOpen}) => {
    const handleClick = () => {
        console.log("clicked!");
        setSupportModalOpen(true);
    }

    const text = "Not satisfied with the answer?"
    return (
        <div style={{alignContent:'center', marginTop: 20}}>
                <p className='message__markdown' style={{fontWeight: 'bold'}}>
                    {text}
                </p>
                <Button variant="contained" style={{fontSize: '9px', color:'black', backgroundColor: '#B3E5FC',}} onClick={() => handleClick()}>
                    <div style={{display:'flex'}}>
                        <MdSupportAgent style={{marginTop:3.5, marginRight:10}} />
                        <p>Talk to Customer Support</p>
                    </div>
                </Button>
            {/* </p> */}
        </div>
    )
};

export default Support;