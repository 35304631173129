import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function Dropdown({prop, setProp, dropdownList, error, defaultValue}) {

  const handleChange = (event) => {
    event.preventDefault();
    setProp(event.target.value);
  };

  return (
    <div>
      <FormControl required style={{ width: '100%' }} error={error}>
        <InputLabel id="demo-simple-select-helper-label" style={{zIndex: 2, top: "-13%"}}>{defaultValue}</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={prop}
          label={`${defaultValue} *`}
          onChange={handleChange}
          size='small'
        >
          {
            dropdownList.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))
          }
        </Select>
        <FormHelperText>Please select your {defaultValue}</FormHelperText>
      </FormControl>
    </div>
  );
}
