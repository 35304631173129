import content from "../textMessages/content.json";

export async function verifyLogin(loginObject) {
  // Create an object with the account name and password
  try {
    const response = await fetch(content.signUpURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(loginObject)
    });

    const data = await response.json();
    if (response.ok) {
      // Check if the login was successful
      if (data.success) {
        console.log("login successful");
        return [true, data.hash]; // Login successful
      } else {
        console.log("login failed");
        return [false, data.hash]; // Login failed
      }
    } else {
      throw new Error(data.error); // Handle non-200 response
    }
  } catch (error) {
    console.error('Error:', error.message);
    return [false, '']; // Login failed
  }
}